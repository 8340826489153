<template>
  <table class="table">
    <tr v-for="(l, index) in list" :key="index">
      <td>
        <app-checkbox
          :disabled="disabled"
          :value="l.letsMake"
          @input="
            letsMake($event, l.product_id, l.product_name);
            l.letsMake = $event;
          "
          placeholder
        ></app-checkbox>
      </td>
      <td>{{ l.product_name.substring(1) }}</td>
    </tr>
  </table>
</template>

<script>
import { mapState } from "vuex";
import { DentalClinicService } from "../service";

export default {
  computed: {
    ...mapState({
      config: s => s.config.dental.orthodontic
    }),
    renderDetails() {
      return this.details.map(x => {
        x.detail_name = x.detail_name.substring(1);
        return x;
      });
    }
  },
  data: () => ({
    list: []
  }),
  watch: {
    details() {
      this.list = JSON.parse(JSON.stringify(this.config.initial_products));
      this.details.map(x => {
        // this.list.filter()
        for (let i in this.list) {
          // console.log(i);
          if (this.list[i].product_name == x.detail_name) {
            this.list[i].letsMake = true;
          }
        }
      });
    }
  },
  props: {
    disabled: { default: false },
    details: {
      default: () => []
    },
    treatment_plan_id: {},
    proform_id: {}
  },
  methods: {
    letsMake(letsMake, product_id, product_name) {
      if (letsMake) {
        var detail = {};
        detail.treatment_plan_id = this.treatment_plan_id;
        detail.product_id = product_id;
        detail.maked = false;
        detail.budgeted = true;
        detail.detail_name = product_name;
        detail.proform_id = this.proform_id;
        DentalClinicService.saveTreatmentPlanDetail(detail);
      }
    }
  }
};
</script>

<style>
</style>
