<template>
  <div>
    <form @submit.prevent="save()">
      <div class="form-group">
        <label for>Cliente</label>
        <SelectClient
          :disabled="disabled"
          ref="selCli"
          v-model="client_id"
        ></SelectClient>
      </div>
      <div class="form-group">
        <label>Edad</label>
        <input
          type="text"
          class="form-control"
          disabled
          :value="
            $refs.selCli && $refs.selCli.val && $refs.selCli.val.birthdate | age
          "
        />
      </div>
      <div class="form-group" v-show="id">
        <label for>Fecha de Vencimiento</label>
        <app-datepicker
          :disabled="disabled"
          v-model="expiration"
        ></app-datepicker>
      </div>
      <div class="form-group">
        <label for>Profesional Encargado</label>
        <SelectEmployee
          :disabled="disabled"
          @open="wantsChangeEmployee = true"
          @input="saveToUpdate()"
          ref="selEmp"
          v-model="employee_id"
          :specialties="specialty_id"
        ></SelectEmployee>
      </div>
      <div class="form-group" v-show="!money.default">
        <label for>Moneda</label>
        <app-select-currency
          :disabled="disabled"
          v-model="money_code"
        ></app-select-currency>
      </div>
      <app-button-submit v-show="!disabled && !this.id"></app-button-submit>
    </form>

    <app-modal-basic
      ref="dClientForm"
      :lg="true"
      modalTitle="REGISTRAR PACIENTE"
    >
      <ClientForm
        ref="clientForm"
        @submitted="
          $refs.selCli.setValueFromId($event);
          $refs.dClientForm.hide();
        "
      ></ClientForm>
    </app-modal-basic>
  </div>
</template>

<script>
import SelectClient from "../../store-module/clients/Select";
import SelectEmployee from "../../admin-module/employees/Select";
import ClientForm from "../../store-module/clients/Form";

import { DentalClinicService } from "../service";
import { mapState } from "vuex";

export default {
  components: {
    SelectClient,
    SelectEmployee,
    ClientForm
  },
  props: {
    group: { default: 1 },
    disabled: { default: false }
  },
  computed: {
    ...mapState({
      money: (s) => s.config.money,
      config: (s) => s.config.dental.proform,
      specialty_id: (s) => s.config.dental.treatments.specialty_id
    })
  },
  data: () => ({
    id: null,
    client_id: null,
    expiration: null,
    employee_id: null,
    money_code: null,
    used: null,
    wantsChangeEmployee: false
  }),
  mounted() {
    /** Nuevo registro */
    if (this.$route.query.new && !this.$route.query.clientId) {
      this.$refs.dClientForm.show();
      this.$refs.clientForm.reset();
      this.id = null;
      // this.expiration = myMoment()
      //   .add(this.config.days_to_init, "days")
      //   .format("YYYY-MM-DD");
    } else if (this.$route.query.clientId) {
      this.$refs.selCli.setValueFromId(this.$route.query.clientId);
    }
  },
  methods: {
    saveToUpdate() {
      if (this.wantsChangeEmployee && this.id) {
        this.save();
      }
    },
    load(reg) {
      this.client_id = reg.client_id;
      this.expiration = reg.expiration;
      this.employee_id = reg.employee_id;
      this.money_code = reg.money_code;
      this.used = reg.used;
      this.$refs.selCli.setValueFromId(reg.client_id);
      this.$refs.selEmp.setValueFromId(reg.employee_id);
      this.id = reg.id;
    },
    save() {
      DentalClinicService.saveProForm({
        id: this.id,
        expiration: this.expiration,
        money_code: this.money.default ? this.money.default : this.money_code,
        client_id: this.client_id,
        employee_id: this.employee_id,
        group: this.group // ortodoncia
      }).then((proform_id) => {
        if (!this.id) {
          this.$emit("submitted", proform_id);
          this.id = proform_id;
        }
      });
    }
  }
};
</script>

<style></style>
