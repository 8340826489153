var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',[_vm._v("PROSPECTO ORTODONCIA")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-md-4"},[_c('ProformHead',{ref:"formHead",attrs:{"disabled":_vm.proform_id,"group":"4"},on:{"submitted":function($event){_vm.$router.push({
            path: '/dental-clinic/proform-othodontic/' + $event
          });
          _vm.getProform();}}}),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.proform_id),expression:"proform_id"}],staticClass:"btn btn-primary",on:{"click":function($event){return _vm.$refs.dBegining.show()}}},[_vm._v(" Iniciar Tratamiento ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.proform_id),expression:"proform_id"}],staticClass:"col col-md-8"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col"},[_c('router-link',{staticClass:"btn btn-secondary mr-1",attrs:{"to":'/dental-clinic/patients/' +
                _vm.reg.client_id +
                '/treatment-plans/' +
                _vm.reg.treatment_plan_id}},[_vm._v("Tratamiento")]),_c('router-link',{staticClass:"btn btn-secondary mr-1",attrs:{"to":'/dental-clinic/patients/' +
                _vm.reg.client_id +
                '/orthodontics/plan/' +
                _vm.reg.treatment_plan_id}},[_vm._v("Plan de tratamiento")])],1)]),_c('InitialForm',{ref:"initialForm",attrs:{"client_id":_vm.reg.client_id}})],1)]),_c('app-modal-yn',{ref:"dBegining",on:{"yes":function($event){return _vm.begins()}}},[_c('p',[_vm._v("Iniciar tratamiento de ortodoncia?")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }