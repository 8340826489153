<template>
  <div>
    <div class="row">
      <div class="col">
        <!-- <div class="form-group">
          <label for>Linea media</label>
          <input type="text" class="form-control" v-model="middleLine" list="listSUg" />
          <datalist id="listSUg">
            <option value="CENTRADO"></option>
            <option value="DESVIADO"></option>
          </datalist>
        </div>-->
        <div class="form-group">
          <label for>Tipo de Ortodoncia</label>
          <div>
            <div class="custom-control custom-radio custom-control-inline">
              <input
                @change="save()"
                type="radio"
                id="customRadioInline1"
                name="customRadioInline1"
                class="custom-control-input"
                :value="1"
                v-model="type_id"
              />
              <label class="custom-control-label" for="customRadioInline1"
                >Preventivo</label
              >
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input
                @change="save()"
                type="radio"
                id="customRadioInline2"
                name="customRadioInline1"
                class="custom-control-input"
                :value="2"
                v-model="type_id"
              />
              <label class="custom-control-label" for="customRadioInline2"
                >Correctivo</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for>ORDEN AREA ORTODONCIA</label>
          <PreTests
            :treatment_plan_id="treatment_plan_id"
            :proform_id="proform_id"
            :details="details"
          ></PreTests>
        </div>
      </div>
      <div class="col">
        <label for>INTERCONSULTAS</label>
        <table class="table table-sm">
          <tr v-for="l in config.quote_specialties" :key="l">
            <td>{{ l }}</td>
            <td>
              <app-checkbox
                :disabled="true"
                :value="quotes[l]"
                placeholder
              ></app-checkbox>
            </td>
            <td>
              <button
                class="btn btn-sm btn-light mr-1"
                v-show="!quotes[l]"
                @click="
                  $refs.dQuote.show();
                  $refs.quoteForm.reset();
                  $refs.quoteForm.setSpecialty(l);
                  $refs.dQuote.values = l;
                "
              >
                <i class="fa fa-calendar"></i>
                Dar cita
              </button>

              <small class="mr-2" v-show="quotes[l]">{{ quotes[l] }}</small>

              <button
                class="btn btn-sm btn-secondary"
                @click="
                  $refs.dTareaContacto.show();
                  $refs.dTareaContacto.values = l;
                "
              >
                <i class="fa fa-phone"></i>
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- <div class="form-group row">
      <div class="col-md-6">
        <label for="">ORDEN AREA ODONTOLOGIA</label>
      <table class="table">
        <tr>
          <td>Rx Periapical</td>
          <td><input type="text" class="form-control" placeholder="pieza"> </td>
          
        </tr>
        <tr>
          <td>Rx Periapical</td>
          <td><input type="text" class="form-control" placeholder="pieza"> </td>
        </tr>
      </table>
      </div>
    </div>-->

    <div class="form-group">
      <label for>EVALUACION DEL PACIENTE</label>
      <table class="table table-sm">
        <tr>
          <td>
            <app-checkbox
              v-model="req_1"
              placeholder="Requiere Exodoncia"
            ></app-checkbox>
          </td>
          <td>
            <input
              type="text"
              class="form-control-sm form-control"
              placeholder
            />
          </td>
        </tr>
        <tr>
          <td>
            <app-checkbox
              v-model="req_2"
              placeholder="Requiere Cirugia"
            ></app-checkbox>
          </td>
          <td>
            <input
              type="text"
              class="form-control-sm form-control"
              placeholder
            />
          </td>
        </tr>
        <tr>
          <td>Sobre la Apartologia</td>
          <td>
            <input
              type="text"
              class="form-control-sm form-control"
              placeholder
            />
          </td>
        </tr>
      </table>
    </div>

    <div class="form-group">
      <label for>Tiempo de tratamiento</label>
      <input
        type="text"
        class="form-control"
        placeholder
        :value="duration"
        disabled
      />
    </div>

    <div class="form-group">
      <label for>Observaciones</label>
      <app-textarea v-model="obs"></app-textarea>
    </div>

    <app-modal-basic ref="dQuote" :xl="true">
      <h4>
        CITA DE INTERCONSULTA
        <span class="text-uppercase">{{
          $refs.dQuote ? $refs.dQuote.values : ""
        }}</span>
      </h4>
      <QuoteForm
        @submitted="reload()"
        :treatment_plan_id="treatment_plan_id"
        ref="quoteForm"
        :p_client_id="client_id"
      >
        <button class="btn btn-success" @click="$refs.dQuote.hide()">OK</button>
      </QuoteForm>
    </app-modal-basic>

    <app-modal-basic ref="dTareaContacto">
      <h4 v-if="$refs.dTareaContacto">{{ $refs.dTareaContacto.values }}</h4>
      <p>Programar llamada por hacer</p>
      <div class="form-group">
        <label for>Fecha</label>
        <app-datepicker></app-datepicker>
      </div>
      <div class="form-group">
        <label for>Observacion</label>
        <app-textarea></app-textarea>
      </div>
      <app-button-submit
        text="Programar"
        @click="$refs.dTareaContacto.hide()"
      ></app-button-submit>
    </app-modal-basic>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DentalClinicService } from "../service";
import QuoteForm from "../quotes/Form";
import PreTests from "./PreTests";
import { myMoment } from "../../utils/myMoment";

export default {
  components: {
    QuoteForm,
    PreTests
  },

  computed: {
    ...mapState({
      config: s => s.config.dental.orthodontic
    })
  },

  data: () => ({
    duration: null,
    id: null,
    reg: {},
    type_id: null,
    middleLine: null,
    jobs: {},
    req_1: null,
    req_2: null,
    quotes: {},
    obs: "",
    // client_id: null,
    details: []
  }),
  // ort: {
  //   consList: [
  //     "CIRUGIA",
  //     "PERIODONCIA",
  //     "IMPLANTE",
  //     "REHABILITACION",
  //     "ESTETICA",
  //     "PEDIATRIA"
  //   ]
  // },
  props: {
    treatment_plan_id: {},
    proform_id: {},
    client_id: {}
  },

  watch: {
    treatment_plan_id() {
      this.getInitial();
    }
  },

  mounted() {
    // this.getInitial();
    this.$emit("mounted");
  },

  methods: {
    reload() {
      this.getInitial();
    },

    getInitial() {
      DentalClinicService.getOrthodontic({
        treatment_plan_id: this.treatment_plan_id
      }).then(res => {
        this.load(res);
      });
    },

    load(reg) {
      // console.log(reg);
      this.id = reg.id;
      this.type_id = reg.type_id;
      this.details = reg.details;
      this.duration = reg.duration;

      for (let x in reg.quotes) {
        if (reg.quotes[x]) {
          reg.quotes[x] = myMoment(reg.quotes[x]).format("DD/MM/YYYY");
        }
      }

      this.quotes = reg.quotes;
      // .map(x => {
      //   // return x = myMoment(x).format("DD/MM/YYYY");
      // });

      // console.log(reg.quotes);

      // reg.quotes.map(x => {
      //   this.quotes[x.specialty_name] = x;
      // });

      // this.middleLine = reg.middleLine;
      // this.typeId = reg.typeId;
      // this.jobs = reg.jobs;
      // this.req_1 = reg.req_1;
      // this.req_2 = reg.req_2;
      // this.quotes = reg.quotes;
      // this.obs = reg.obs;
    },
    save() {
      DentalClinicService.saveOrthodontic({
        ...this.$data,
        treatment_plan_id: this.treatment_plan_id
      }).then(res => {
        this.id = res.id;
        this.$emit("submitted", res);
      });
    },
    reset() {}
  }
};
</script>

<style>
</style>
