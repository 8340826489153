<template>
  <div>
    <h4>PROSPECTO ORTODONCIA</h4>
    <div class="row">
      <div class="col col-md-4">
        <ProformHead
          :disabled="proform_id"
          group="4"
          ref="formHead"
          @submitted="
            $router.push({
              path: '/dental-clinic/proform-othodontic/' + $event
            });
            getProform();
          "
        ></ProformHead>
        <button
          v-show="proform_id"
          class="btn btn-primary"
          @click="$refs.dBegining.show()"
        >
          Iniciar Tratamiento
        </button>
      </div>
      <div class="col col-md-8" v-show="proform_id">
        <div class="row mb-2">
          <div class="col">
            <router-link
              :to="
                '/dental-clinic/patients/' +
                  reg.client_id +
                  '/treatment-plans/' +
                  reg.treatment_plan_id
              "
              class="btn btn-secondary mr-1"
              >Tratamiento</router-link
            >
            <router-link
              :to="
                '/dental-clinic/patients/' +
                  reg.client_id +
                  '/orthodontics/plan/' +
                  reg.treatment_plan_id
              "
              class="btn btn-secondary mr-1"
              >Plan de tratamiento</router-link
            >
          </div>
        </div>
        <InitialForm :client_id="reg.client_id" ref="initialForm"></InitialForm>
      </div>
    </div>

    <app-modal-yn ref="dBegining" @yes="begins()">
      <!-- <div class="form-group">
        <label for="">Fecha de Vencimiento</label>
        <app-datepicker v-model="$refs.formHead.expiration"></app-datepicker>
      </div> -->
      <p>Iniciar tratamiento de ortodoncia?</p>
    </app-modal-yn>
  </div>
</template>

<script>
import ProformHead from "../proforms/FormCreate";
import { DentalClinicService } from "../service";
import InitialForm from "./Initial";

export default {
  components: {
    ProformHead,
    InitialForm
  },
  props: {
    proform_id: {}
  },
  data: () => ({
    reg: {}
  }),
  methods: {
    begins() {
      DentalClinicService.saveTreatmentPlan({
        proform_id: this.proform_id,
        name: "ORTODONCIA",
        employee_id: this.reg.employee_id,
        details: []
      }).then(res => {
        this.$router.push(
          "/dental-clinic/patients/" +
            this.reg.client_id +
            "/treatment-plans/" +
            res
        );
      });
    },
    getProform() {
      DentalClinicService.getProform(this.proform_id).then(res => {
        // console.log(res);
        this.$refs.formHead.load(res);
        this.reg = res;

        DentalClinicService.getOrthodonticHistory(res.client_id).then(his => {
          this.$refs.initialForm.load(his.adds);
        });
      });
    }
  },
  mounted() {
    if (this.proform_id) {
      this.getProform();
    }
  }
};
</script>

<style>
</style>
